var render = function render(){var _vm=this,_c=_vm._self._c;return _c('date-range-picker',{ref:"picker",staticClass:"w-100",attrs:{"opens":"right","locale-data":{ firstDay: 1, format: 'dd/mm/yyyy' },"close-on-esc":false,"showDropdowns":true,"control-container-class":"form-control max-h-45px","single-date-picker":"","ranges":false,"timePicker":"","label":_vm.label},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('div',{staticClass:"h-100 d-flex align-center"},[_c('input',{staticClass:"w-100",staticStyle:{"outline":"none"},attrs:{"type":"text","placeholder":_vm.label},domProps:{"value":_vm.datetime}})])]}},{key:"footer",fn:function(picker){return [_c('div',{staticClass:"drp-buttons py-4 px-6 poppins d-flex"},[_c('button',{staticClass:"btn btn-light ls1 py-2 px-7",attrs:{"type":"button"},on:{"click":() => {
            picker.clickCancel;
            _vm.clear();
          }}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn--export-filter ls1 py-2 px-4",attrs:{"type":"button"},on:{"click":() => {
            picker.clickApply();
            _vm.onChangeDateRange();
          }}},[_vm._v(" Apply ")])])]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})
}
var staticRenderFns = []

export { render, staticRenderFns }