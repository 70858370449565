<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      ref="dataTable"
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :detailsComponent="componentForDetails"
      :delete_url="delete_url"
      :isFilterRequiredForExport="true"
      :urls="urls"
      :isWarehouseRequired="true"
      :actionsComponent="null"
      :recordDetailComponent="componentForRecordDetail"
    >
      <template
        v-slot:topRightActions="{
          permissions,
          selectedItems,
        }"
      >
        <div
          class="ms-1 mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('on_hold')"
        >
          <button
            class="btn btn--export-filter font-weight-bolder"
            @click.prevent="
              () => actionOnHold([...selectedItems.map((item) => item.id)])
            "
          >
            On Hold
          </button>
        </div>

        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('release')"
        >
          <button
            class="btn btn--export-filter font-weight-bolder"
            @click.prevent="
              () => actionRelease([...selectedItems.map((item) => item.id)])
            "
          >
            Release
          </button>
        </div>

        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
        >
          <ActionsButton
            :selectedItems="selectedItems"
            :permissions="permissions"
            :clearSelected="() => $refs.dataTable.clearSelected()"
            :refresher="customRefresher"
          />
        </div>
      </template>

      <template v-slot:rowActions="{ item, permissions }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissions('on_hold')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.on_hold"
                :class="
                  !item.extra_data.detail.actions.on_hold
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="actionOnHold([item.id])"
                text
              >
                On Hold
              </v-btn>
            </div>
            <div v-if="permissions('release')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.release"
                :class="
                  !item.extra_data.detail.actions.release
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="
                  () => {
                    actionRelease([item.id]);
                  }
                "
                text
                >Release
              </v-btn>
            </div>
            <div v-if="permissions('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.edit"
                :class="
                  !item.extra_data.detail.actions.edit
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="
                  () => {
                    actionEdit(item);
                  }
                "
                text
                >Edit
              </v-btn>
            </div>
            <div v-if="permissions('copy_inventory')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.copy"
                :class="
                  !item.extra_data.detail.actions.copy
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="
                  () => {
                    actionCopy(item);
                  }
                "
                text
                >Copy
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </DataTable>
    <OnHold ref="onHold" :set-reason-type="setReasonType"></OnHold>
    <EditItem ref="editItem" :refresher="customRefresher" />
    <EditItem ref="copyItem" mode="copy" :refresher="customRefresher" />
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DataTable from "@/own/components/DataTable.vue";
import OnHold from "@/own/components/warehouseManagement/inventoryOverview/OnHold.vue";
import EditItem from "@/own/components/warehouseManagement/inventoryOverview/EditItem.vue";
import ActionsButton from "@/own/components/warehouseManagement/inventoryOverview/ActionsButton.vue";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/inventoryOverview.module";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import RecordDetail from "@/own/components/RecordDetail.vue";

export default {
  name: "InventoryOverview",
  components: { DataTable, OnHold, EditItem, ActionsButton },
  data: () => ({
    componentForEditing: null,
    componentForAdding: null,
    componentForDetails: null,
    componentForRecordDetail: RecordDetail,

    delete_url: `${process.env.VUE_APP_BASE_URL}/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINVENTOVERVIEWTableFilters",
      getTableState: "getINVENTOVERVIEWTableState",
      getTableData: "getINVENTOVERVIEWTableData",
      getTableHeaders: "getINVENTOVERVIEWTableHeaders",
      getTableProperties: "getINVENTOVERVIEWTableProperties",
      getTableExportUrl: "getINVENTOVERVIEWExportUrl",
      getTablePermissions: "getINVENTOVERVIEWTablePermissions",
      getUploadType: "getINVENTOVERVIEWUploadType",
    },
    urls: {
      details: "/warehouse_management/inventory_overview/show",
    },
  }),
  methods: {
    pageloader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    actionOnHold(ids) {
      this.$refs.onHold.toggleModal(ids);
    },
    actionEdit(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.editItem.toggleModal();
    },
    actionCopy(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.copyItem.toggleModal();
    },
    setReasonType(data) {
      this.submitOnHold(data);
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    async customRefresher() {
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      };
      this.pageloader(true);
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.selected = [];
        });
      this.pageloader(false);
    },
    submitOnHold(data) {
      this.pageloader(true);
      ApiService.post(
        "/warehouse_management/inventory_overview/on_hold_update",
        data
      )
        .then(() => {
          SwalService.successMessage({
            title: "Success",
            html: "Successfully updated",
          });
          this.pageloader(false);
          this.$refs.dataTable.clearSelected();
          this.customRefresher();
        })
        .finally(() => {
          this.pageloader(false);
        });
    },
    actionRelease(item_ids) {
      this.pageloader(true);
      ApiService.post("/warehouse_management/inventory_overview/release", {
        ids: item_ids,
      })
        .then(() => {
          SwalService.successMessage({
            title: "Success",
            html: "Successfully released",
          });
          this.pageloader(false);
          this.$refs.dataTable.clearSelected();
          this.customRefresher();
        })
        .finally(() => {
          this.pageloader(false);
        });
    },
  },
  computed: {
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function(val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
