<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">HS Code Summary</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div>
          <v-select
            v-model="formData.type"
            label="Type"
            item-text="text"
            item-value="index"
            :items="items"
            clearable
            dense
            outlined
            :error-messages="typeErrors"
            @input="$v.formData.type.$touch()"
            @blur="$v.formData.type.$touch()"
          ></v-select>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click="submit"
            type="submit"
            class="btn btn--export-filter mr-3 px-5 py-3 ls1"
          >
            Export
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import {
  required,
  // requiredIf,
} from "vuelidate/lib/validators";
import { UPDATE_TABLE_DATA } from "@/core/services/store/standardQualityCheck.module.js";
import ApiService from "@/core/services/api.service";

export default {
  name: "HscodeDialog",
  mixins: [validationMixin],
  props: ["pageLoader"],
  validations: {
    formData: {
      type: { required },
    },
  },

  data: () => ({
    dialog: false,
    formData: {
      type: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearData();
      }
      this.dialog = !this.dialog;
    },

    clearData() {
      this.formData = {
        type: null,
      };
      this.$v.$reset();
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.pageLoader(true);

      const payload = {
        id: this.$store.getters.getItemForAction,
        ...this.formData,
      };
      ApiService.post("/fulfillment/orders/export-hscode-summary", payload)
        .then(({ data }) => {
          if (data.success) {
            Swal.fire({
              title: `Success`,
              html: `Downloading`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            const link = document.createElement("a");
            link.href = data.url;
            link.target = "_blank";
            link.download = "doc.csv";
            link.click();
          } else {
            Swal.fire({
              title: `Error`,
              html: `Something went wrong`,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
          });
          this.toggleModal();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
    items: function() {
      return [
        { index: "picking", text: "Picking" },
        { index: "packing", text: "Packing" },
      ];
    },
    typeErrors: function() {
      return this.handleFormValidation("type");
    },
  },
};
</script>
