<template v-if="isDataLoaded">
  <div class="p-4">
    <dashboard-layout
      :pageTitle="pageTitle"
      :lineChart="false"
      :additionalIfo="false"
    ></dashboard-layout>
  </div>
</template>

<script>
import DashboardLayout from "@/own/components/dashboard/DashboardWesLayout.vue";
import { SET_DASHBOARD_URL } from "@/core/services/store/dashboardWes.module";

export default {
  components: { DashboardLayout },
  name: "SplWes",
  data() {
    return {
      pageTitle: {
        header: "SPL WES",
        subHeader: "SPL Dashboard",
      },
    };
  },
  computed: {
    isDataLoaded: function () {
      return (
        this.$store.getters.getSPLWESTableData.length > 0 &&
        this.$store.getters.getSPLWESFilter.length > 0
      );
    },
  },
  created() {
    this.$store.commit(SET_DASHBOARD_URL, "/dashboards/dashboardSplWes");
  },
};
</script>
