<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1400"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-3 align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{ isForEdit ? "Edit Record" : "Copy Record" }}
        </h4>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="w-100 d-flex flex-column multiple-edit-scrollbar">
          <div
            class="w-100 d-flex flex-row align-center py-2 my-4"
            v-for="(formData, index) in formDataItems"
            :key="index"
          >
            <template v-if="!isForEdit">
              <div class="h-50px d-flex align-start mx-1" v-if="index === 0">
                <button
                  class="btn btn--export-filter btn-icon px-5 py-3 ls1 w-100"
                  @click="() => splitItem()"
                >
                  <span class="svg-icon me-2">
                    <v-icon size="18">mdi-plus-box</v-icon>
                  </span>
                  <span>Split</span>
                </button>
              </div>

              <div class="h-50px d-flex align-start mx-1" v-else>
                <button
                  class="btn btn--export-filter btn-icon px-5 py-3 ls1 w-100"
                  @click="() => removeSplitted(index)"
                >
                  <span class="svg-icon me-2">
                    <v-icon size="18">mdi-minus-box</v-icon>
                  </span>
                  <span>Remove</span>
                </button>
              </div>
            </template>
            <div class="h-50px mx-1">
              <v-autocomplete
                class="w-200px"
                label="Warehouse"
                v-model="formData.warehouse_id"
                :items="serverData.warehouses"
                item-name="text"
                item-value="index"
                outlined
                dense
                hide-details
                readonly
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                class="w-200px"
                label="Customer"
                v-model="formData.customer_id"
                :items="serverData.customers"
                item-name="text"
                item-value="index"
                outlined
                dense
                hide-details
                readonly
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                class="w-200px"
                v-model="formData.sku"
                label="SKU"
                outlined
                dense
                hide-details
                readonly
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                readonly
                class="w-200px"
                v-model="formData.asn_number"
                label="ASN number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1" v-if="isForEdit">
              <v-text-field
                class="w-85px"
                v-model.number="formData.qty"
                label="Quantity"
                type="number"
                :min="0"
                outlined
                dense
                hide-details
                disabled
              />
            </div>
            <div class="h-50px mx-1" v-else>
              <v-text-field
                class="w-110px"
                v-model.number="formData.qty"
                label="Quantity"
                type="number"
                :min="0"
                :max="maxQty"
                outlined
                dense
                hide-details
                :disabled="index === 0"
              />
              <!--                :max="index !== 0 ? maxQty : 99999999999"-->
              <!--                @change="(qty) => handleQtyChange(qty, index)"-->
            </div>
            <div class="h-50px mx-1" v-if="isForEdit">
              <v-text-field
                class="w-125px"
                v-model="formData.available_qty"
                label="Available qty"
                type="number"
                :min="0"
                outlined
                dense
                hide-details
                disabled
              />
            </div>
            <div class="h-50px mx-1" v-if="isForEdit">
              <v-text-field
                class="w-125px"
                v-model="formData.allocated_qty"
                label="Allocated qty"
                type="number"
                :min="0"
                outlined
                dense
                hide-details
                read-only
                disabled
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.location_id"
                :items="serverData.locations"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="Location"
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.tote_id"
                label="Tote"
                outlined
                dense
                hide-details
              />
            </div>
            <!--          <div class="h-50px mx-1">-->
            <!--            <v-text-field-->
            <!--            :readonly=!isForEdit && index === 0-->
            <!--              class="w-200px"-->
            <!--              v-model="formData.lpn_id"-->
            <!--              label="LPN"-->
            <!--              outlined-->
            <!--              dense-->
            <!--              hide-details-->
            <!--            />-->
            <!--          </div>-->
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-150px"
                v-model="formData.good_or_damage"
                :items="serverData.goods"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                label="Good/Damage"
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.damage_reason_id"
                :items="serverData.damage_reasons"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="Damage reason"
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-150px"
                v-model="formData.is_on_hold"
                :items="[
                  { text: 'Yes', index: 1 },
                  { text: 'No', index: 0 },
                ]"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="Is on hold"
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.on_hold_reason_id"
                :items="serverData.on_hold_reasons"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="On hold reason"
              />
            </div>

            <!--          <div class="h-50px mx-1">-->
            <!--            <v-text-field-->
            <!--            :readonly=!isForEdit && index === 0-->
            <!--              class="w-200px"-->
            <!--              v-model="formData.batch_number"-->
            <!--              label="Batch number"-->
            <!--              outlined-->
            <!--              dense-->
            <!--              hide-details-->
            <!--            />-->
            <!--          </div>-->
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.customer_batch_number"
                label="Customer batch number"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.serial_no"
                label="Serial no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.serial_2_no"
                label="Serial(2) no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.lot_no"
                label="Lot no"
                outlined
                dense
                hide-details
              />
            </div>

            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.invoice_no"
                label="Invoice no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.po_no"
                label="PO no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.roll_no"
                label="Roll no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
                :disabled="!isForEdit && index === 0"
                hide-details
              >
                <v-date-picker v-model="formData.exp_date" clearable />

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :readonly="!isForEdit && index === 0"
                    class="w-200px"
                    v-model="formData.exp_date"
                    label="Expire date"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    hide-details
                    outlined
                    clearable
                  />
                </template>
              </v-menu>
            </div>
            <div class="h-50px mx-1">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
                :disabled="!isForEdit && index === 0"
                hide-details
              >
                <v-date-picker v-model="formData.mfg_at" clearable />

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :readonly="!isForEdit && index === 0"
                    class="w-200px"
                    v-model="formData.mfg_at"
                    label="Manufacture date"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    hide-details
                    outlined
                    clearable
                  />
                </template>
              </v-menu>
            </div>

            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-150px"
                v-model="formData.net_weight"
                label="Net weight"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-150px"
                v-model="formData.gross_weight"
                label="Gross weight"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-150px"
                v-model="formData.base_uom_id"
                :items="serverData.base_uoms"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="UOM"
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-150px"
                v-model="formData.volume"
                label="Volume"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.volume_uom_id"
                :items="serverData.volume_uoms"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="Volume UOM"
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.container_no"
                label="Container no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.hs_code"
                label="HS code"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.unit_rate"
                label="Unit rate"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.unit_rate_currency_id"
                :items="serverData.currencies"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="Currency"
              />
            </div>
            <div class="h-50px mx-1">
              <v-autocomplete
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.country_id"
                :items="serverData.countries"
                outlined
                item-name="text"
                item-value="index"
                dense
                hide-details
                clearable
                label="Country"
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.import_entry_no"
                label="Import entry"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.custom_entry_no"
                label="Customer entry"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.bl_no"
                label="BL no"
                outlined
                dense
                hide-details
              />
            </div>

            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.vin_no"
                label="Vin no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.engine_no"
                label="Engine no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.body_model"
                label="Body model"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.specification"
                label="Specification"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.drive"
                label="Drive"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.key_no"
                label="Key no"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.engine_capacity"
                label="Engine capacity"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.grade"
                label="Grade"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
                :disabled="!isForEdit && index === 0"
                hide-details
              >
                <v-date-picker v-model="formData.mfg_at" clearable />

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :readonly="!isForEdit && index === 0"
                    class="w-200px"
                    v-model="formData.mfg_at"
                    label="Manufacture date"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    hide-details
                    outlined
                    clearable
                  />
                </template>
              </v-menu>
            </div>
            <div class="h-50px mx-1">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
                hide-details
              >
                <v-date-picker
                  v-model="formData.year_of_mnf"
                  clearable
                  type="year"
                />

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :readonly="!isForEdit && index === 0"
                    class="w-200px"
                    v-model="formData.year_of_mnf"
                    label="Manufacture year"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    hide-details
                    outlined
                    clearable
                  />
                </template>
              </v-menu>
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.customer_pallet_id"
                label="Customer pallet"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="h-50px mx-1">
              <v-text-field
                :readonly="!isForEdit && index === 0"
                class="w-200px"
                v-model="formData.condition"
                label="Condition"
                outlined
                dense
                hide-details
              />
            </div>
          </div>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="toggleModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn--export-filter px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import validationMixin from "@/own/mixins/validationMixin";
import SwalService from "@/core/services/swal.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

export default {
  name: "MultipleEdit",
  // mixins: [validationMixin],
  // validations: {
  //   formData: {
  //     location_id: { required },
  //   },
  // },
  props: {
    refresher: {
      type: Function,
    },
    clearSelected: {
      type: Function,
    },
    // mode: {
    //   type: String,
    //   default: "edit",
    // },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    source_inventory_id: 0,
    maxQty: 0,
    isForEdit: true,
    formDataItems: [],
  }),
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    loadDataFromServer(ids, isForEdit = true) {
      this.isForEdit = isForEdit;
      this.pageLoader(true);
      ApiService.post(
        `/warehouse_management/inventory_overview/${
          this.isForEdit ? "edit" : "get_source_inventory"
        }`,
        {
          ids,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        }
      )
        .then(({ data }) => {
          const { inventories, ...rest } = data;
          this.serverData = rest;

          if (isForEdit) {
            this.formDataItems = [...inventories];
          } else {
            const { id, ...rest } = inventories[0];
            this.formDataItems[0] = rest;
            this.source_inventory_id = id;
            this.maxQty = this.formDataItems[0].qty - 1;
          }

          this.dialog = true;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    // handleQtyChange(qty, index) {
    //   let totalQty = this.maxQty + 1 - qty;
    //   if (qty < 0) {
    //     this.formDataItems[index].qty = 0;
    //     return;
    //   }
    //
    //   for (let i = 1; i < this.formDataItems.length; i++) {
    //     if (index !== i) {
    //       if (totalQty - this.formDataItems[i].qty > 0) {
    //         totalQty -= this.formDataItems[i].qty;
    //         this.formDataItems[0].qty = totalQty;
    //       } else {
    //         this.formDataItems[0].qty = 1;
    //         this.formDataItems[index].qty = 1;
    //         return;
    //       }
    //     }
    //   }
    // },
    splitItem() {
      if (!this.formDataItems[this.formDataItems.length - 1].qty) {
        SwalService.warningMessage({
          // title: this.$t("please_select_at_least_one_item"),
          title: "Please add quantity for previous item",
          timer: 10000,
        });
        return;
      }

      if (this.formDataItems[0].qty <= 1) {
        return;
      }
      this.formDataItems.push({ ...this.formDataItems[0], qty: null });
    },
    removeSplitted(index) {
      this.formDataItems.splice(index, 1);
    },
    submitEditForm() {
      this.pageLoader(true);
      const items = [];
      if (!this.isForEdit) {
        if (this.formDataItems.length < 2) {
          SwalService.warningMessage({
            title: "Please add item for split",
            timer: 10000,
          });
          this.pageLoader(false);
          return;
        }
        let found = false;
        for (let i = 1; i < this.formDataItems.length; i++) {
          if (!this.formDataItems[i].qty) {
            found = true;
            break;
          }
          items.push({ ...this.formDataItems[i] });
        }
        if (found) {
          SwalService.warningMessage({
            title: "Please add quantity",
            timer: 10000,
          });
          this.pageLoader(false);
          return;
        }
      }

      const payload = {
        inventories: this.isForEdit ? this.formDataItems : items,
      };
      if (!this.isForEdit) {
        payload.source_inventory_id = this.source_inventory_id;
      }
      ApiService.post(
        `/warehouse_management/inventory_overview/${
          this.isForEdit ? "update" : "copy"
        }`,
        payload
      )
        .then(() => {
          SwalService.successMessage({
            title: SwalService.titles.success,
            html: SwalService.messages.successifully_completed,
          });
          this.toggleModal();
          this.clearSelected();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.formDataItems = [];
        this.maxQty = 0;
        this.clearSelected();
      } else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
  },
};
</script>

<style scoped>
.multiple-edit-scrollbar {
  overflow-x: scroll;
  position: relative;
  //height: calc(100vh - 190px) !important;
}

.multiple-edit-scrollbar::-webkit-scrollbar {
  height: 52px !important;
  width: 52px !important;
}

.multiple-edit-scrollbar::-webkit-scrollbar-thumb {
  height: 52px !important;
  border-radius: 52px !important;
}
</style>
