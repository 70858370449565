<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :actionsComponent="actionsComponent"
      @item-selected="onselectItem"
      ref="dtable"
    >
      <template v-slot:tableTop>
        <div
          class="d-block d-sm-flex justify-content-between align-items-center"
        >
          <div
            class="min-w-250px max-w-500px py-0 font-weight-medium w-100 text-capitalize col-sm-12 col-12 col-md-8 pl-0"
          >
            <v-text-field
              v-model="system_code"
              :label="$t('system_code')"
              type="text"
              autofocus
              outlined
              clearable
              class="poppins"
              append-icon="mdi-barcode-scan"
              @click:append="updateTableContent"
              @keyup.enter="updateTableContent"
            />
          </div>
        </div>
      </template>

      <template v-slot:topActions>
        <PackageCodeActions
          :selected="selected"
          :clearSelected="() => $refs.dtable.clearSelected()"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import PackageCodeActions from "@/own/components/warehouseManagement/packageCode/PackageCodeActions.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PER_PAGE,
  SET_TABLE_SORT_BY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/packageCode.module.js";
import ActionsComponent from "@/own/components/warehouseManagement/packageCode/ActionsComponent";

export default {
  name: "PackageCode",
  components: { DataTable, PackageCodeActions },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORT_BY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PER_PAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getPACKAGECODETableFilters",
      getTableState: "getPACKAGECODETableState",
      getTableData: "getPACKAGECODETableData",
      getTableHeaders: "getPACKAGECODETableHeaders",
      getTableProperties: "getPACKAGECODETableProperties",
      getTableExportUrl: "getPACKAGECODEExportUrl",
      getTablePermissions: "getPACKAGECODETablePermissions",
      getUploadType: "getPACKAGECODEUploadType",
    },
    system_code: null,
    actionsComponent: ActionsComponent,
    selected: [],
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  computed: {
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        filters: [
          {
            name: "system_code",
            value: this.system_code,
          },
        ],
      });
      this.$store.commit(SET_PAGE_LOADING, false);
    },
    onselectItem(items) {
      this.selected = items;
    },
  },
};
</script>
