<template>
  <v-card id="consignee" class="mt-4">
    <v-card-title class="primary h5 text-white"
      >{{ item.title }}
      <v-spacer />
      <v-btn
        v-if="item?.header_actions?.edit_order"
        class="bg-light"
        @click="showEditLightModal"
        outlined
        >Edit</v-btn
      >
    </v-card-title>
    <v-card-text class="w-400px">
      <div
        class="d-flex align-items-center my-2"
        v-for="(element, index) in item.data"
        :key="index"
        style="display: flex"
      >
        <div
          class="other_key font-weight-bolder font-size-lg"
          style="width: 40%; text-align: left; padding-right: 10px"
        >
          {{ element.title }}
        </div>
        <div
          class="font-weight-bolder font-size-lg"
          style="width: 10px; text-align: center"
        >
          :
        </div>
        <div
          class="other_value font-size-lg ml-3"
          :style="element.style"
          style="text-align: left"
        >
          {{ element.value }}
        </div>
      </div>
    </v-card-text>

    <EditLight
      ref="editLight"
      :refresher="refresher"
      :toggleDetail="toggleDetail"
    />
  </v-card>
</template>

<script>
import EditLight from "@/own/components/fulfillment/orders/EditLight.vue";

export default {
  name: "PopUpTextGroup",
  components: { EditLight },
  props: ["item", "order_id", "refresher", "toggleDetail"],
  methods: {
    showEditLightModal() {
      this.$refs.editLight.toggleModal(this.order_id);
    },
  },
};
</script>
