import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import cod from "./cod.module";
import roles from "./roles.module";
import users from "./users.module";
import clients from "./clients.module";
import apps from "./apps.module";
import orders from "./orders.module";
import skus from "./skus.module";
import bundles from "./bundles.module";
import inventory from "./inventory.module";
import accounts from "./accounts.module";
import main from "./main.module";
import stores from "./stores.module";
import contacts from "./contacts.module";
import custom from "./custom.module";
import sliders from "./sliders.module";
import categories from "./categories.module";
import products from "./products.module";
import buyers from "./buyers.module";
import fulfillment from "./fulfillment.module";
import delivery from "./delivery.module";
import analytics from "./analytics.module";
import platforms from "./platforms.module";
import messages from "./messages.module";
import integrations from "./integrations.module";
import datahubs from "./datahubs.module";
import emails from "./emails.module";
import mailtemplates from "./mailtemplates.module";
import services from "./services.module";
import couriers from "./couriers.module";
import courierAccounts from "./courierAccounts.module";
import courierStatus from "./courierStatus.module";
import countries from "./countries.module";
import supplierMapping from "./supplierMapping.module";
import shippingCustom from "./shippingCustom.module";
import shippingMethods from "./shippingMethods.module";
import statusProcessing from "./statusProcessing.module";
import skusMapping from "./skusMapping.module";
import asn from "./asn.module";
import reviews from "./reviews.module";
import coupons from "./coupons.module";
import testimonials from "./testimonials.module";
import suppliers from "./suppliers.module";
import returned from "./returned.module";
import skuGroups from "./skuGroups.module";
import agings from "./agings.module";
import organizations from "./organizations.module";
import organizationActionFlow from "./organizationActionFlow.module";
import systemCredentials from "./systemCredentials.module";
import finservices from "./finservices.module";
import fincustomer from "./fincustomer.module";
import invoices from "./invoices.module";
import payments from "./payments.module";
import fileUpload from "./fileUpload.module";
import qc from "./qc.module";
import report from "./reports.module";
import bankAccounts from "./bankAccounts.module";
import addresses from "./addresses.module";
import discovers from "./discovers.module";
import filter from "./filter.module";
import callAssignment from "./callAssignment.module";
import callSheet from "./callSheet.module";
import trackingPage from "./trackingPage.module";
import reportAgent from "./reportAgent.module";
import warehouses from "./warehouses.module";
import paymentType from "./paymentType.module";
import shipping_countries from "./shipping_countries.module";
import pendingAuditOrders from "./pendingAuditOrders.module";
import currency_managements from "./currency_managements.module";
import pendingConfirmationOrders from "./pendingConfirmationOrders.module";
import exchange_managements from "./exchange_managements.module";
import shipping_regions from "./shipping_regions.module";
import sidebar from "./sidebar.module";
import qcChecklists from "./qcChecklists.module";
import customerNotifications from "./customerNotifications.module";
import subscriptionManagement from "./subscriptionManagement.module";
import subscriptionBuyers from "./subscriptionBuyers.module";
import ruleBuilder from "./ruleBuilder.module";
import putawayRules from "./putawayRules.module";
import receiving from "./receiving.module";
import picking from "./picking2.module";
import packing from "./packing2.module";
import omsRules from "./omsRules.module";
import giftCards from "./giftCards.module";
import putawayRuleBasedTemplate from "./putawayRuleBasedTemplate.module";
import transactionLogs from "./transactionLogs.module";
import inventoryBalance from "./inventoryBalance.module";
import pickupOrders from "./pickupOrders.module";
import integrationAuth from "./integration_auth.module";
import integrations2 from "./integrations2.module";
import messages2 from "./messages2.module";
import putawayTasks from "./putawayTasks.module";
import allocationRules from "./allocationRules.module";
import allocationRuleBasedTemplate from "./allocationRuleBasedTemplate.module";
import fulfillmentTask from "./fulfillmentTasks.module";
import courierAreaListMapping from "./courierAreaListMapping.module";
import fulfillmentProducts from "./fulfillmentProducts.module";
import carrierAccounts from "./carrierAccounts.module";
import InventorySnapShot from "./inventorySnapshot.module";
import pickingAssignment from "./pickingAssignment.module";
import warehouseBasic from "./warehouseBasic.module";
import skuBrands from "./skuBrands.module";
import dashboardReturn from "./dashboardReturn.module";
import inventoryAdjustment from "./inventoryAdjustment.module";
import locationMovement from "./locationMovement.module";
import ShippingWMS from "./shipping_wms.module";
import ReturnIntegration from "./return_integration.module";
import B2BPickAndPack from "./b2bpickandpack.module";
import warehouseStore from "./warehouseStore.module";
import skuMovement from "./skuMovement.module";
import skippedFulfillmentTasks from "./skippedFulfillmentTasks.module";
import reportsDatatable from "./reportsDatatable.module";
import randomCycleCount from "./randomCycleCount.module";
import warehouseDashboard from "./warehouseDashboard.module";
import standardCycleCount from "./standardCycleCount.module";
import translations from "./translations.module";
import waves from "./waves.module";
import waveLogs from "./waveLogs.module";
import allocationStrategyRules from "./allocationStrategyRules.module";
import courierAssignRules from "./courierAssignRules.module";
import couriersRuleLogs from "./couriersRuleLogs.module";
import wes from "./wes.module";
import wesConfigurationAccount from "./wesConfigurationAccount.module";
import wesConfigurationChutes from "./wesConfigurationChutes.module";
import wesConfigurationStations from "./wesConfigurationStations.module";
import wesBFLContainers from "./wesBFLContainers.module";
import wesBFLSync from "./wesBFLSync.module";
import wesSortingLocations from "./wesSortingLocations.module";
import carrierShipmentApiRequestLogsLogs from "./carrierShipmentApiRequestLogsLogs.module";
import standardQualityCheckModule from "./standardQualityCheck.module";
import customerCases from "./customerCases.module";
import sortationOrders from "./sortationOrders.module";
import rcoPickingList from "./rcoPickingList.module";
import userTypes from "./userTypes.module";
import division from "./division.module";
import locationMapping from "./locationMapping.module";
import webhooks from "./webhooks.module";
import webhookLogs from "./webhookLogs.module";
import returns from "./returns.module";
import wesSplWaves from "./wesSplWaves.module";
import wesSplSortingItemConfiguration from "./wesSplSortingItemConfiguration.module";
import myReports from "./myreports.module";
import wesExceptionReasons from "./wesExceptionReasons.module";
import wesSortPlan from "./wesSortPlan.module";
import wesSPLTransactionMonitoring from "./wesSPLTransactionMonitoring.module";
import wesSplBarCode from "./wesSplBarCode.module";
import wesSplMonitoring from "./wesSplMonitoring.module";
import sortationSKUs from "./sortationSKUs.module";
import sortationSKUCases from "./sortationSKUCases.module";
import integrationCountries from "./integrationCountries.module";
import integrationOrderStatus from "./integrationOrderStatus.module";
import platformShippingMethods from "./platformShippingMethods.module";
import platformStatusProcessAction from "./platformStatusProcessAction.module";
import reasonCode from "./reasonCode.module";
import standardReceiving from "./standardReceiving.module";
import inboundPackaging from "./inboundPackaging.module";
import StandardPutaways from "./standardPutaway.module";
import StandardGrn from "./standardGrn.module";
import standardOrderPicking from "./standardOrderPicking.module";
import standardOrderPackaging from "./standardOrderPackaging.module";
import standardOrderPutaway from "./standardOrderPutaway.module";
import standardOrderDispatch from "./standardOrderDispatch.module";
import standardGrnHistoryDatatable from "./standardGrnHistoryDatatable.module";
import cycleCounts from "./cycleCounts.module";
import standardPackageMovement from "./standardPackageMovement.module";
import uomConversions from "./uomConversions.module";
import organizationAddressBook from "./organizationAddressBook.module";
import packageCode from "./packageCode.module";
import dispatchVehicle from "./dispatchVehicle.module";
import UOMAndPackageTypes from "./UOMAndPackageTypes.module";
import dynamicDocument from "./dynamicDocument.module";
import pickingStrategyRules from "./pickingStrategyRules.module";
import sellerAddresses from "./sellerAddresses.module";
import dataParsing from "./dataParsing.module";
import inventoryOverview from "./inventoryOverview.module";
import scanningConfigurationTemplates from "./scanningConfigurationTemplates.module";
import dynamicReport from "./dynamicReport.module";
import smtpSettings from "./smtpSettings.module";
import emailReports from "./emailReports.module";
import pendingAuditReturns from "./pendingAuditReturns.module";
import returnConfiguration from "./returnConfiguration.module.js";
import splWesDashboard from "./dashboardWes.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    reviews,
    coupons,
    testimonials,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    cod,
    roles,
    users,
    clients,
    apps,
    orders,
    skus,
    bundles,
    inventory,
    accounts,
    main,
    stores,
    contacts,
    custom,
    fulfillment,
    sliders,
    categories,
    products,
    buyers,
    delivery,
    analytics,
    platforms,
    messages,
    integrations,
    datahubs,
    emails,
    mailtemplates,
    services,
    couriers,
    courierAccounts,
    courierStatus,
    countries,
    supplierMapping,
    shippingCustom,
    shippingMethods,
    statusProcessing,
    skusMapping,
    asn,
    suppliers,
    returned,
    skuGroups,
    agings,
    organizations,
    organizationActionFlow,
    systemCredentials,
    finservices,
    fincustomer,
    invoices,
    payments,
    fileUpload,
    qc,
    report,
    bankAccounts,
    addresses,
    discovers,
    filter,
    callAssignment,
    callSheet,
    trackingPage,
    reportAgent,
    warehouses,
    paymentType,
    shipping_countries,
    pendingAuditOrders,
    currency_managements,
    pendingConfirmationOrders,
    exchange_managements,
    shipping_regions,
    sidebar,
    qcChecklists,
    customerNotifications,
    subscriptionManagement,
    subscriptionBuyers,
    ruleBuilder,
    putawayRules,
    receiving,
    picking,
    packing,
    omsRules,
    giftCards,
    putawayRuleBasedTemplate,
    transactionLogs,
    inventoryBalance,
    pickupOrders,
    integrationAuth,
    integrations2,
    messages2,
    putawayTasks,
    allocationRules,
    allocationRuleBasedTemplate,
    fulfillmentTask,
    courierAreaListMapping,
    fulfillmentProducts,
    carrierAccounts,
    InventorySnapShot,
    pickingAssignment,
    warehouseBasic,
    skuBrands,
    dashboardReturn,
    inventoryAdjustment,
    locationMovement,
    ShippingWMS,
    ReturnIntegration,
    B2BPickAndPack,
    warehouseStore,
    skuMovement,
    skippedFulfillmentTasks,
    reportsDatatable,
    randomCycleCount,
    warehouseDashboard,
    standardCycleCount,
    translations,
    waves,
    waveLogs,
    allocationStrategyRules,
    courierAssignRules,
    couriersRuleLogs,
    wes,
    wesConfigurationAccount,
    wesConfigurationChutes,
    wesConfigurationStations,
    wesBFLContainers,
    wesBFLSync,
    wesSortingLocations,
    carrierShipmentApiRequestLogsLogs,
    standardQualityCheckModule,
    customerCases,
    sortationOrders,
    rcoPickingList,
    userTypes,
    division,
    locationMapping,
    webhooks,
    webhookLogs,
    returns,
    wesSplWaves,
    wesSplSortingItemConfiguration,
    myReports,
    wesExceptionReasons,
    wesSortPlan,
    wesSPLTransactionMonitoring,
    wesSplBarCode,
    wesSplMonitoring,
    sortationSKUs,
    sortationSKUCases,
    integrationCountries,
    integrationOrderStatus,
    platformShippingMethods,
    platformStatusProcessAction,
    reasonCode,
    standardReceiving,
    inboundPackaging,
    StandardPutaways,
    StandardGrn,
    standardOrderPicking,
    standardOrderPackaging,
    standardOrderPutaway,
    standardOrderDispatch,
    standardGrnHistoryDatatable,
    cycleCounts,
    standardPackageMovement,
    uomConversions,
    organizationAddressBook,
    packageCode,
    dispatchVehicle,
    UOMAndPackageTypes,
    dynamicDocument,
    pickingStrategyRules,
    sellerAddresses,
    dataParsing,
    inventoryOverview,
    scanningConfigurationTemplates,
    dynamicReport,
    smtpSettings,
    emailReports,
    pendingAuditReturns,
    returnConfiguration,
    splWesDashboard,
  },
});
