<template>
  <v-row>
    <v-col v-if="!isClient" cols="12" md="6">
      <v-autocomplete
        v-model="formData.customer_id"
        label="Customer"
        item-text="text"
        item-value="index"
        :items="serverData.customers"
        clearable
        outlined
        dense
        :error-messages="customerIdErrors"
        @change="onChangeCustomer"
        @click:clear="onClearCustomer"
        @input="$v.formData.customer_id.$touch()"
        @blur="$v.formData.customer_id.$touch()"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="formData.return_type_id"
        label="Return Type"
        item-text="text"
        item-value="index"
        :items="serverData.return_types"
        clearable
        outlined
        dense
        @change="onClearReturnType"
        :error-messages="returnTypeErrors"
        @input="$v.formData.return_type_id.$touch()"
        @blur="$v.formData.return_type_id.$touch()"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="types.collection_customer_address_type"
        label="Collection Address Type"
        item-text="title"
        item-value="index"
        :items="collectionAddressTypes"
        clearable
        outlined
        dense
        :error-messages="collectionAddressTypeErrors"
        @input="$v.types.collection_customer_address_type.$touch()"
        @blur="$v.types.collection_customer_address_type.$touch()"
        @change="onChangeCollectionAddressType"
        ><template
          v-if="types.collection_customer_address_type === 4"
          v-slot:append-outer
        >
          <button
            class="btn btn-info ml-n2 mt-n2"
            @click="createCustomLocation('collection')"
          >
            <span class="svg-icon ml-2 mb-2">
              <v-icon size="18">mdi-pencil</v-icon>
            </span>
          </button>
        </template></v-select
      >
    </v-col>
    <!-- if collection address type is carrier -->
    <v-col
      v-if="
        isCollectionAddressVisible &&
        types.collection_customer_address_type === 1
      "
      cols="12"
      md="6"
    >
      <v-autocomplete
        v-model="formData.collection_carrier_address_id"
        label="Collection Address"
        item-text="text"
        item-value="index"
        :items="collectionAddressSuggestions"
        :disabled="!types.collection_customer_address_type"
        clearable
        outlined
        dense
        @change="onChangeCollectionAddress"
        :error-messages="collectionCarrierAddressErrors"
        @input="$v.formData.collection_carrier_address_id.$touch()"
        @blur="$v.formData.collection_carrier_address_id.$touch()"
      ></v-autocomplete>
    </v-col>
    <!-- if collection address type is warehouse -->
    <v-col
      v-if="
        isCollectionAddressVisible &&
        types.collection_customer_address_type === 2
      "
      cols="12"
      md="6"
    >
      <v-autocomplete
        v-model="formData.collection_warehouse_id"
        label="Collection Address"
        item-text="text"
        item-value="index"
        :items="collectionAddressSuggestions"
        :disabled="!types.collection_customer_address_type"
        clearable
        outlined
        dense
        @change="onChangeCollectionAddress"
        :error-messages="collectionWarehouseAddressErrors"
        @input="$v.formData.collection_warehouse_id.$touch()"
        @blur="$v.formData.collection_warehouse_id.$touch()"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="types.destination_address_type"
        label="Destination Address Type"
        item-text="title"
        item-value="index"
        :items="destinationAddressTypes"
        clearable
        outlined
        dense
        @change="onChangeDestinationAddressType"
        ><template
          v-if="types.destination_address_type === 4"
          v-slot:append-outer
        >
          <button
            class="btn btn-info ml-n2 mt-n2"
            @click="createCustomLocation('destination')"
          >
            <span class="svg-icon ml-2 mb-2">
              <v-icon size="18">mdi-pencil</v-icon>
            </span>
          </button>
        </template></v-select
      >
    </v-col>
    <!-- if destination address type is carrier -->
    <v-col v-if="types.destination_address_type === 1" cols="12" md="6">
      <v-autocomplete
        v-model="formData.destination_carrier_address_id"
        label="Destination Address"
        item-text="text"
        item-value="index"
        :items="destinationAddressSuggestions"
        clearable
        outlined
        dense
        @change="onChangeDestinationAddress"
        :error-messages="destinationCarrierAddressErrors"
        @input="$v.formData.destination_carrier_address_id.$touch()"
        @blur="$v.formData.destination_carrier_address_id.$touch()"
      >
      </v-autocomplete>
    </v-col>
    <!-- if destination address type is destination -->
    <v-col v-if="types.destination_address_type === 2" cols="12" md="6">
      <v-autocomplete
        v-model="formData.destination_warehouse_id"
        label="Destination Address"
        item-text="text"
        item-value="index"
        :items="destinationAddressSuggestions"
        clearable
        outlined
        dense
        @change="onChangeDestinationAddress"
        :error-messages="destinationWarehouseAddressErrors"
        @input="$v.formData.destination_warehouse_id.$touch()"
        @blur="$v.formData.destination_warehouse_id.$touch()"
      >
      </v-autocomplete>
    </v-col>
    <!-- if destination address type is customer warehouse -->
    <v-col v-if="types.destination_address_type === 3" cols="12" md="6">
      <v-autocomplete
        v-model="formData.destination_customer_address_id"
        label="Destination Address"
        item-text="text"
        item-value="index"
        :items="destinationAddressSuggestions"
        clearable
        outlined
        dense
        @change="onChangeDestinationAddress"
        :error-messages="destinationCustomerAddressErrors"
        @input="$v.formData.destination_customer_address_id.$touch()"
        @blur="$v.formData.destination_customer_address_id.$touch()"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12">
      <div class="d-flex">
        <v-select
          v-model="search_type"
          class="m-0 p-0 max-w-150px type-selector"
          value="awb"
          :disabled="
            !selectedCustomer ||
            !formData.return_type_id ||
            actionType === 'edit'
          "
          item-text="text"
          item-value="value"
          :items="[
            { text: 'AWB', value: 'awb' },
            { text: 'Client Reference', value: 'client_reference' },
            { text: 'Order Number', value: 'order_no' },
          ]"
          outlined
          dense
        ></v-select>
        <v-combobox
          v-if="formData.return_type_id === 6"
          v-model="orders"
          class="search-skus-internal"
          label="Orders"
          :disabled="
            !selectedCustomer ||
            !formData.return_type_id ||
            actionType === 'edit'
          "
          @change="handleOrderInput"
          clearable
          :multiple="isRto"
          :small-chips="isRto"
          outlined
          dense
          :deletable-chips="isRto"
        />
        <v-text-field
          v-else
          v-model="orders"
          class="search-skus-internal"
          label="Orders"
          :disabled="
            !selectedCustomer ||
            !formData.return_type_id ||
            actionType === 'edit'
          "
          clearable
          :multiple="isRto"
          :small-chips="isRto"
          outlined
          dense
          :deletable-chips="isRto"
        />

        <v-btn
          @click="addOrders"
          height="40px"
          :disabled="
            !selectedCustomer ||
            !formData.return_type_id ||
            actionType === 'edit'
          "
          depressed
          color="primary"
          class="ml-3"
          >Add {{ isRto ? "Orders" : "Order" }}</v-btn
        >
      </div>
    </v-col>
    <!-- SKU Datatable -->
    <v-col cols="12">
      <SkuDataTable
        :items="order_details"
        v-on:updateOrderDetails="updateOrderDetails"
      ></SkuDataTable>
      <CustomAddressForm
        ref="customAddressForm"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :addressType="customAddressType"
      ></CustomAddressForm>
    </v-col>
  </v-row>
</template>

<script>
import {
  SET_SELECTED_CUSTOMER,
  SET_COLLECTION_ADDRESS_TYPE,
  SET_DESTINATION_ADDRESS_TYPE,
  SET_INTERNAL_AVAILABLE_SKUS,
} from "@/core/services/store/returns.module";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SkuDataTable from "@/own/components/shipping/returns/internal/SkuDataTable.vue";
import CustomAddressForm from "@/own/components/shipping/returns/internal/CustomAddressForm.vue";

export default {
  name: "InternalOrderDetails",
  components: { SkuDataTable, CustomAddressForm },
  props: ["getOrderDetails", "returnId"],
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        return_type_id: { required },
        customer_id: { required },
        collection_carrier_address_id: {
          required: requiredIf(function () {
            return this.types.collection_customer_address_type === 1;
          }),
        },
        collection_warehouse_id: {
          required: requiredIf(function () {
            return this.types.collection_customer_address_type === 2;
          }),
        },
        destination_carrier_address_id: {
          required: requiredIf(function () {
            return this.types.destination_address_type === 1;
          }),
        },
        destination_warehouse_id: {
          required: requiredIf(function () {
            return this.types.destination_address_type === 2;
          }),
        },
        destination_customer_address_id: {
          required: requiredIf(function () {
            return this.types.destination_address_type === 3;
          }),
        },
      },
      types: {
        collection_customer_address_type: { required },
      },
    };
  },
  data: () => ({
    search_type: "awb",
    orders: null,
    order_details: [],
    customAddressType: null,
    types: {
      collection_customer_address_type: null,
      destination_address_type: null,
    },
    formData: {
      customer_id: null,
      return_type_id: null,
      collection_carrier_address_id: null,
      collection_warehouse_id: null,
      destination_carrier_address_id: null,
      destination_warehouse_id: null,
      destination_customer_address_id: null,
    },
  }),
  mounted() {
    if (!this.isClient) {
      this.formData.customer_id =
        this.$store.getters.currentUser.data.customer_id;
    }
  },
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    updateOrderDetails(val) {
      const index = this.order_details.findIndex(
        (item) => item.order_id === val.order.order_id
      );
      this.order_details[index].skus = val.skus;
      this.getOrderDetails(this.order_details);
    },
    onClearCustomer() {
      this.orders = null;
      this.order_details = null;
    },
    handleOrderInput(val) {
      if (this.formData.return_type_id === 6) {
        let seperated = [];
        val.forEach((element) => {
          let subElement = element.trim().split(/(?:,| )+/);
          subElement.forEach((sub) => {
            if (seperated.indexOf(sub) === -1) {
              seperated.push(sub);
            }
          });
        });
        this.orders = seperated;
      }
    },
    addOrders() {
      if (this.orders) {
        if (!this.isRto) {
          this.order_details = [];
        }
        this.$store.commit(SET_PAGE_LOADING, true);

        const type = {
          awb: "original_awb",
          client_reference: "original_client_reference",
          order_no: "original_order_number",
        };
        const payload = {
          query: typeof this.orders === "string" ? [this.orders] : this.orders,
          customer_id: this.selectedCustomer,
          type: type[this.search_type],
          id: this.returnId.id,
        };

        ApiService.post(
          `/shipping/returns/search_return_order_details`,
          payload
        )
          .then(({ data }) => {
            this.orders = null;
            if (data.error_orders) {
              const response = data.message
                .map((res) => `<div>${res}</div><br>`)
                .join("");
              Swal.fire({
                title: "Order status",
                html: response,
                icon: "warning",
                showConfirmButton: true,
                // timer: 2500,
              });
            }
            if (data.data.length) {
              this.$store.commit(
                SET_INTERNAL_AVAILABLE_SKUS,
                data.data[0].skus
              );
              data.data.map((item) => {
                const index = this.order_details.findIndex((order) => {
                  return order.order_id === item.order_id;
                });

                if (index === -1) {
                  this.order_details.push(item);
                }
              });
            }
          })
          .then(() => {
            if (this.actionType === "edit" && this.returnType === "iq") {
              this.order_details[0].skus = [...this.editData.skus];
            }
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    onChangeCustomer() {
      this.$store.commit(SET_SELECTED_CUSTOMER, this.formData.customer_id);
    },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
    checkErrors() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.order_details.length) {
        Swal.fire({
          title: "Warning",
          text: `No Order selected`,
          icon: "warning",
          showConfirmButton: true,
          timer: 2500,
        });
      }
    },
    clearFields() {
      this.search_type = "awb";
      this.order = null;
      this.order_details = [];
      this.types = {
        collection_customer_address_type: null,
        destination_address_type: null,
      };
      this.formData = {
        customer_id: null,
        return_type_id: null,
        collection_address_id: null,
        destination_customer_address_id: null,
      };
      this.$v.$reset();
    },
    onClearReturnType() {
      if (this.actionType !== "edit") {
        this.orders = null;
        this.order_details = [];
      }
    },
    getEditData() {
      const data = this.editData.orderDetails;
      this.formData = {
        customer_id: data.customer_id,
        return_type_id: data.return_type_id,
        destination_address_type: data.destination_address_type,
      };
      if (data.collection_customer_address_type === 1) {
        this.formData.collection_carrier_address_id =
          data.collection_carrier_address_id;
      } else if (data.collection_customer_address_type === 2) {
        this.formData.collection_warehouse_id = data.collection_warehouse_id;
      }

      if (data.destination_address_type == 1) {
        this.formData.destination_carrier_address_id =
          data.destination_carrier_address_id;
      } else if (data.destination_address_type == 2) {
        this.formData.destination_warehouse_id = data.destination_warehouse_id;
      } else if (data.destination_address_type == 3) {
        this.formData.destination_customer_address_id =
          data.destination_customer_address_id;
      }
      this.types = {
        collection_customer_address_type: data.collection_customer_address_type,
        destination_address_type: data.destination_address_type,
      };
      if (data.source_of_order === "iq") {
        if (data.original_client_reference) {
          this.search_type = "client_reference";
          if (data.return_type_id === 6)
            this.orders = [data.original_client_reference];
          else this.orders = data.original_client_reference;
        } else if (data.original_awb) {
          this.search_type = "awb";
          if (data.return_type_id === 6) this.orders = [data.original_awb];
          else this.orders = data.original_awb;
        } else if (data.original_order_number) {
          this.search_type = "order_no";
          if (data.return_type_id === 6)
            this.orders = [data.original_order_number];
          else this.orders = data.original_order_number;
        }
        this.addOrders();
      }
      // this.formData = { ...this.editData.orderDetails };
    },
    onChangeDestinationAddress() {
      const type = this.types.destination_address_type;
      if (type === 1) {
        this.formData.destination_warehouse_id = null;
        this.formData.destination_customer_address_id = null;
      } else if (type === 2) {
        this.formData.destination_carrier_address_id = null;
        this.formData.destination_customer_address_id = null;
      } else if (type === 3) {
        this.formData.destination_carrier_address_id = null;
        this.formData.destination_warehouse_id = null;
      }
    },
    onChangeCollectionAddress() {
      const type = this.types.collection_customer_address_type;
      if (type === 1) {
        this.formData.collection_warehouse_id = null;
      } else if (type === 2) {
        this.formData.collection_carrier_address_id = null;
      }
    },
    createCustomLocation(type) {
      this.pageLoader();
      this.customAddressType = type;
      setTimeout(() => this.$refs.customAddressForm.toggleModal(), 300);
    },
    onChangeCollectionAddressType() {
      this.$store.commit(
        SET_COLLECTION_ADDRESS_TYPE,
        this.types.collection_customer_address_type
      );
    },
    onChangeDestinationAddressType() {
      this.$store.commit(
        SET_DESTINATION_ADDRESS_TYPE,
        this.types.destination_address_type
      );
    },
  },
  computed: {
    selectedCustomer: function () {
      return this.$store.getters.getRTRNSelectedCustomer;
    },
    customerIdErrors: function () {
      return this.handleFormValidation("customer_id", this, "formData");
    },
    returnTypeErrors: function () {
      return this.handleFormValidation("return_type_id", this, "formData");
    },
    collectionAddressTypeErrors: function () {
      return this.handleFormValidation(
        "collection_customer_address_type",
        this,
        "types"
      );
    },
    collectionCarrierAddressErrors: function () {
      return this.handleFormValidation(
        "collection_carrier_address_id",
        this,
        "formData"
      );
    },
    collectionWarehouseAddressErrors: function () {
      return this.handleFormValidation(
        "collection_warehouse_id",
        this,
        "formData"
      );
    },
    destinationCarrierAddressErrors: function () {
      return this.handleFormValidation(
        "destination_carrier_address_id",
        this,
        "formData"
      );
    },
    destinationWarehouseAddressErrors: function () {
      return this.handleFormValidation(
        "destination_warehouse_id",
        this,
        "formData"
      );
    },
    destinationCustomerAddressErrors: function () {
      return this.handleFormValidation(
        "destination_customer_address_id",
        this,
        "formData"
      );
    },
    serverData: function () {
      return this.$store.getters.getRTRNServerData;
    },
    isClient: function () {
      return this.$store.getters.currentUser.data.is_client;
    },
    isInvalid: function () {
      return this.$v.$invalid || !this.order_details.length;
    },
    isCollectionAddressVisible: function () {
      return this.types.collection_customer_address_type === 0 ? false : true;
    },
    collectionAddressTypes: function () {
      const types = this.serverData.collection_address_types;
      if (this.formData.return_type_id === 6) {
        return types.slice(1);
      }
      return types;
    },
    destinationAddressTypes: function () {
      const types = this.serverData.destination_address_types;
      if (
        this.types.collection_customer_address_type === 1 ||
        this.types.collection_customer_address_type === 2
      ) {
        return types.slice(1);
      }
      return types;
    },
    collectionAddressSuggestions: function () {
      if (this.types.collection_customer_address_type === 1) {
        return this.serverData.carrier_addresses;
      } else if (this.types.collection_customer_address_type === 2) {
        return this.serverData.warehouses;
      }
      return [];
    },
    destinationAddressSuggestions: function () {
      if (this.types.destination_address_type === 1) {
        return this.serverData.carrier_addresses;
      } else if (this.types.destination_address_type === 2) {
        return this.serverData.warehouses;
      } else if (this.types.destination_address_type === 3) {
        return this.serverData.customer_addresses;
      }
      return [];
    },
    collectionAddressContainer: function () {
      const types = {
        1: "collection_carrier_address_id",
        2: "collection_warehouse_id",
      };
      return types[this.types.collection_address_type] || null;
    },
    destinationAddressContainer: function () {
      const types = {
        1: "destination_carrier_address_id",
        2: "destination_warehouse_id",
        3: "destination_customer_address_id",
      };
      return types[this.types.destination_address_type] || null;
    },
    editData() {
      return this.$store.getters.getRTRNEditData;
    },
    isRto() {
      return this.formData.return_type_id === 6;
    },
    actionType: function () {
      return this.$store.getters.getRTRNActionType;
    },
    returnType() {
      return this.$store.getters.getRTRNCreateReturnType;
    },
  },
  watch: {
    "types.collection_customer_address_type": function (val) {
      if (val === 0) {
        this.formData = {
          ...this.formData,
          collection_warehouse_id: null,
          collection_carrier_address_id: null,
        };
      }
    },
  },
};
</script>

<style lang="scss">
.search-skus-internal {
  .v-input__prepend-outer {
    margin: 0px !important;
  }
  .v-select__selection {
    font-size: 13px !important;
  }
}
.type-selector {
  .v-select__selection {
    font-size: 10px !important;
  }
}
</style>
