<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row w-100">
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.store_name"
              label="Store name"
              clearable
              outlined
              dense
              :error-messages="store_nameErrors"
              @input="$v.formData.store_name.$touch()"
              @blur="$v.formData.store_name.$touch()"
            />
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.store_reference"
              label="Store reference"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.store_url"
              label="Store url"
              clearable
              outlined
              dense
              :error-messages="store_urlErrors"
              @input="$v.formData.store_url.$touch()"
              @blur="$v.formData.store_url.$touch()"
            />
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.shipper_name"
              label="Shipper name"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-6">
            <v-file-input
              v-model="formData.logo"
              show-size
              counter
              multiple
              dense
              hide-details
              outlined
              clearable
              accept="image/png, image/jpeg, image/bmp"
              append-icon="mdi-camera"
              prepend-icon=""
              :label="$t('logo')"
            />
          </div>
          <div class="col-12 col-md-6">
            <v-select
              v-model="formData.default_order_type_id"
              label="Order type"
              item-text="text"
              item-value="index"
              :items="serverData.order_types"
              outlined
              dense
              :error-messages="default_order_type_idErrors"
              @input="$v.formData.default_order_type_id.$touch()"
              @blur="$v.formData.default_order_type_id.$touch()"
            />
          </div>
          <div class="col-12 col-md-6">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              item-text="text"
              item-value="index"
              :items="serverData.statuses"
              outlined
              dense
              :error-messages="statusErrors"
              @input="$v.formData.status.$touch()"
              @blur="$v.formData.status.$touch()"
            />
          </div>

          <div class="col-12 col-md-6">
            <v-autocomplete
              v-model="formData.skip_order_status"
              label="Skip order status"
              :items="booleanValues"
              item-text="text"
              item-value="index"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-6">
            <v-autocomplete
              v-model="formData.mark_order_as_paid"
              label="Mark order as paid"
              :items="booleanValues"
              item-text="text"
              item-value="index"
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-6">
            <v-autocomplete
              v-model="formData.bypass_shipping_address_validation"
              label="Bypass shipping address validation"
              :items="booleanValues"
              item-text="text"
              item-value="index"
              outlined
              dense
            />
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="formData.prefix_replace"
              label="Prefix replace"
              clearable
              min="0"
              type="number"
              @change="() => validateMinValue('prefix_replace', 0, 'formData')"
              outlined
              dense
              :error-messages="prefix_replaceErrors"
              @input="$v.formData.prefix_replace.$touch()"
              @blur="$v.formData.prefix_replace.$touch()"
            />
          </div>
          <div class="col-12 text-center pb-4">
            <h3>Credentials</h3>
            <v-jsoneditor v-model="formData.credentials" :options="options" />
          </div>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 mt-8 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import VJsoneditor from "v-jsoneditor/src/index";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  components: { VJsoneditor },
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      default_order_type_id: { required },
      prefix_replace: { required },
      status: { required },
      store_name: { required },
      store_url: { required },

    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      default_order_type_id: null,
      prefix_replace: null,
      status: null,
      store_name: null,
      store_reference: null,
      store_url: null,
      shipper_name: null,
      logo: null,
      credentials: {},
      skip_order_status: 0,
      bypass_shipping_address_validation: 0,
      mark_order_as_paid: 0
    },
    options: { mode: "code", navigationBar: true, mainMenuBar: false },
  }),
  methods: {
    loadDataFromServer() {
      let sendData = { id: this.$store.getters.getItemForAction.id };
      this.pageLoader(true);
      ApiService.post("/datahub/integrationsNew/edit", sendData)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.integration;
          if (typeof response.data.integration.credentials === "string") {
            this.formData.credentials = JSON.parse(
              response.data.integration.credentials
            );
          }
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] &&
            item[0] != "image" &&
            item[0] != "photo" &&
            item[0] != "logo") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], JSON.stringify(item[1]));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (
          item[1] &&
          (item[0] == "image" || item[0] == "photo" || item[0] == "logo")
        ) {
          // console.log(item[1], item[0], item[1][0]);
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // let sendData = this.formData;
      const sendData = this.convertToFormData();
      this.pageLoader(true);
      ApiService.post("/datahub/integrationsNew/update", sendData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Integration data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.formData = {
        id: null,
        default_order_type_id: null,
        prefix_replace: null,
        status: null,
        store_name: null,
        store_reference: null,
        store_url: null,
        shipper_name: null,
        logo: null,
        credentials: {},
        skip_order_status: 0,
        bypass_shipping_address_validation: 0,
        mark_order_as_paid: 0
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    booleanValues: function() {
      return [
        { index: 0, text: "No" },
        { index: 1, text: "Yes" },
      ];
    },
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    default_order_type_idErrors: function() {
      return this.handleFormValidation("default_order_type_id", this);
    },
    prefix_replaceErrors: function() {
      return this.handleFormValidation("prefix_replace", this);
    },
    statusErrors: function() {
      return this.handleFormValidation("status", this);
    },
    store_nameErrors: function() {
      return this.handleFormValidation("store_name", this);
    },
    store_urlErrors: function() {
      return this.handleFormValidation("store_url", this);
    },
  },
};
</script>
