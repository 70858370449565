<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    fullscreen
  >
    <div class="bg-white poppins position-relative min-h-100">
      <div
        class="modal-header py-3 align-center bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">Cycle count detail</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div v-if="data">
          <div v-for="(item, i) in data" :key="i">
            <pop-up-selector :item="item"></pop-up-selector>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PopUpSelector from "@/own/components/warehouseManagement/standardQualityCheck/PopUpSelector.vue";
import { UPDATE_QUALITY_CHECK_DETAILS } from "@/core/services/store/standardQualityCheck.module.js";
import { SET_POPUP_CLOSED_FLAG } from "@/core/services/store/main.module";
export default {
  name: "Others",
  components: { PopUpSelector },
  data: () => ({
    dialog: false,
  }),
  methods: {
    async toggleModal() {
      if (this.dialog) {
        this.other = null;
        this.$store.commit(SET_POPUP_CLOSED_FLAG, true);
      } else {
        await this.loadDataFromServer();
        this.$store.commit(SET_POPUP_CLOSED_FLAG, false);
      }
      this.dialog = !this.dialog;
    },
    async loadDataFromServer() {
      const item = this.$store.getters.getItemForAction;
      await this.$store.dispatch(UPDATE_QUALITY_CHECK_DETAILS, item.id);
    },
  },
  computed: {
    data: function() {
      return this.$store.getters.getSTANDARDQUALITYCHECKDetails;
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
</style>
