import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getRTRNTableStructure";
export const UPDATE_TABLE_DATA = "getRTRNTableData";
export const EXPORT_TABLE_DATA = "exportRTRNTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setRTRNTableStructure";
export const SET_TABLE_DATA = "setRTRNTableData";
export const SET_TABLE_FILTER = "setRTRNTableFilter";
// export const SET_FILTER_CHANGE = "setRTRNFilterChange";
export const SET_TABLE_PAGE = "setRTRNTablePage";
export const SET_TABLE_PERPAGE = "setRTRNTablePerPage";
export const SET_TABLE_SORTBY = "setRTRNTableSortBy";
export const SET_TABLE_SORTORDER = "setRTRNTableSortOrder";
export const SET_EXPORT_LINK = "setRTRNExportLink";
export const SET_CREATE_RETURN_TYPE = "setRTRNCreateReturnType";
export const SET_SERVER_DATA = "setRTRNServerData";
export const SET_COLLECTION_ADDRESS_TYPE = "setRTRNCollectionAddressType";
export const SET_DESTINATION_ADDRESS_TYPE = "setRTRNDestinationAddressType";
export const SET_SELECTED_CUSTOMER = "setRTRNSelectedCustomer";
export const SET_INTERNAL_ORDER_SKUS = "setRTRNInternalOrderSkus";
export const SET_ACTION_TYPE = "setRTRNActionType";
export const SET_EDIT_DATA = "setRTRNEditData";
export const SET_EDITED_SKU = "setRTRNEditedSku";
export const SET_CUSTOM_COLLECTION_ADDRESS = "setRTRNCustomCollectionAddress";
export const SET_CUSTOM_DESTINATION_ADDRESS = "setRTRNCustomDestinationAddress";
export const SET_INTERNAL_AVAILABLE_SKUS = "setRTRNAvailableSkus";

const state = {
  queryObject: {},
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  create_return_type: "external",
  serverData: {},
  collection_address_type: null,
  destination_address_type: 2,
  selected_customer: null,
  internal_order_skus: null,
  action_type: "create",
  edit_data: null,
  edited_sku: null,
  custom_collection_address: {},
  custom_destination_address: {},
  available_skus: [],
};

const getters = {
  /**
   * for table title, description
   * */
  getRTRNTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getRTRNTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getRTRNPrintTypes(state) {
    return state.table_structure.print_labels;
  },
  getRTRNTableState(state) {
    return state.table_state;
  },
  getRTRNTableHeaders(state) {
    return state.table_structure.columns;
  },

  getRTRNTableFilters(state) {
    return state.table_structure.filters;
  },
  getRTRNTableEmailStatuses(state) {
    return state.table_structure.email_statuses;
  },

  getRTRNTableData(state) {
    return state.table_data.data;
  },

  getRTRNExportUrl(state) {
    return state.export_link;
  },
  getRTRNCreateReturnType(state) {
    return state.create_return_type;
  },
  getRTRNServerData(state) {
    return state.serverData;
  },
  getRTRNSelectedCollectionAddressType(state) {
    return state.collection_address_type;
  },
  getRTRNSelectedDestinationAddressType(state) {
    return state.destination_address_type;
  },
  getRTRNSelectedCustomer(state) {
    return state.selected_customer;
  },
  getRTRNInternalOrdersSkus(state) {
    return state.internal_order_skus;
  },
  getRTRNActionType(state) {
    return state.action_type;
  },
  getRTRNEditData(state) {
    return state.edit_data;
  },
  getRTRNCarriers(state) {
    return state.table_structure.carriers;
  },
  getRTRNDestinationAddressType(state) {
    return state.table_structure.destination_address_types;
  },
  getRTRNDestinationCustomerAddresses(state) {
    return state.table_structure.customer_addresses;
  },
  getRTRNDestinationCarrierAddersses(state) {
    return state.table_structure.carrier_addresses;
  },
  getRTRNDestinationWarehouses(state) {
    return state.table_structure.warehouses;
  },
  getRTRNCustomCollectionAddress(state) {
    return state.custom_collection_address;
  },
  getRTRNCustomDestinationAddress(state) {
    return state.custom_destination_address;
  },
  getRTRNAvailableSkus(state) {
    return state.available_skus;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    // console.log("order state of filter update", filter);
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_CREATE_RETURN_TYPE](state, type) {
    state.create_return_type = type;
  },
  [SET_SERVER_DATA](state, data) {
    state.serverData = data;
  },
  [SET_COLLECTION_ADDRESS_TYPE](state, type) {
    state.collection_address_type = type;
  },
  [SET_DESTINATION_ADDRESS_TYPE](state, type) {
    state.destination_address_type = type;
  },
  [SET_SELECTED_CUSTOMER](state, customer) {
    state.selected_customer = customer;
  },
  [SET_INTERNAL_ORDER_SKUS](state, details) {
    state.internal_order_skus = details;
  },
  [SET_ACTION_TYPE](state, type) {
    state.action_type = type;
  },
  [SET_EDIT_DATA](state, data) {
    state.edit_data = data;
  },
  [SET_EDITED_SKU](state, data) {
    state.edited_sku = data;
  },
  [SET_CUSTOM_COLLECTION_ADDRESS](state, data) {
    state.custom_collection_address = data;
  },
  [SET_CUSTOM_DESTINATION_ADDRESS](state, data) {
    state.custom_destination_address = data;
  },
  [SET_INTERNAL_AVAILABLE_SKUS](state, data) {
    state.available_skus = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
