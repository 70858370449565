<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-black font-size-lg ms-5">
        <!-- <v-icon class="mr-3">mdi-{{ headerIcon }}-circle-outline</v-icon> -->
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- COD returns windows day -->
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.cod_return_window_days"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    COD retuns windows days
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row v-if="formData.cod_return_window_days" class="ms-3">
                <v-col md="6">
                  <v-text-field
                    v-model="formData.cod_return_window_days_value"
                    label="Days"
                    dense
                    outlined
                    rounded
                    :error-messages="codReturnWindowError"
                    @input="$v.formData.cod_return_window_days_value.$touch()"
                    @blur="$v.formData.cod_return_window_days_value.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Prepaid returns windows day -->
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.prepaid_return_window_days"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Prepaid return window days
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row v-if="formData.prepaid_return_window_days" class="ms-3">
                <v-col md="6">
                  <v-text-field
                    v-model="formData.prepaid_return_window_days_value"
                    label="Days"
                    dense
                    outlined
                    rounded
                    :error-messages="prepaidReturnWindowError"
                    @input="
                      $v.formData.prepaid_return_window_days_value.$touch()
                    "
                    @blur="
                      $v.formData.prepaid_return_window_days_value.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Refund trigger status -->
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.refund_trigger_status"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Refund trigger status
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row v-if="formData.refund_trigger_status" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="formData.refund_trigger_status_value"
                    label="Status"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.refund_trigger_status"
                    :error-messages="refundTriggerStatusError"
                    @input="$v.formData.refund_trigger_status_value.$touch()"
                    @blur="$v.formData.refund_trigger_status_value.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- enable email service for return -->
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.enable_email_services_for_return"
                dense
                color="#7e04b7"
                :true-value="1"
                :false-value="0"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Enable email service for return
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.automatically_process_pending_return"
                dense
                color="#7e04b7"
                :true-value="1"
                :false-value="0"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Automatically process pending return
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <!-- Submit -->
          <v-row class="justify-center mb-3 mt-6">
            <v-col md="2" class="justify-content-end">
              <!-- <v-btn dark color="purple" @click="submitConfiguration">
                Submit
              </v-btn> -->
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { requiredIf, numeric } from "vuelidate/lib/validators";

export default {
  name: "ExpansionPanel",
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      cod_return_window_days_value: {
        required: requiredIf(function () {
          return this.formData.cod_return_window_days;
        }),
        numeric,
      },
      prepaid_return_window_days_value: {
        required: requiredIf(function () {
          return this.formData.cod_return_window_days;
        }),
        numeric,
      },
      refund_trigger_status_value: {
        required: requiredIf(function () {
          return this.formData.refund_trigger_status;
        }),
      },
    },
  },
  data() {
    return {
      expanded: false,
      serverData: null,
      serverDataLoaded: false,

      formData: {
        cod_return_window_days: null,
        cod_return_window_days_value: null,
        prepaid_return_window_days: null,
        prepaid_return_window_days_value: null,
        refund_trigger_status: null,
        refund_trigger_status_value: null,
        enable_email_services_for_return: null,
        automatically_process_pending_return: null,
      },
    };
  },

  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "returns",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    handleFormValidation: function (fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    /**
     * @param {Array<{key: string, value: string, apply_rule_type: string, customers: Array}>} data - The configuration data to set.
     * @returns void
     * @description set the configuration data to the form data
     */
    setConfigurationData: function (data) {
      Object.entries(this.formData).forEach(([key]) => {
        let index = data.findIndex((item) => item.key === key);
        if (index !== -1) {
          let value = data[index].value;
          this.formData[key] = isNaN(value) ? value : Number(value);
        }
      });
      this.formData.cod_return_window_days_value =
        this.formData.cod_return_window_days;
      this.formData.cod_return_window_days =
        !!this.formData.cod_return_window_days;
      this.formData.prepaid_return_window_days_value =
        this.formData.prepaid_return_window_days;
      this.formData.prepaid_return_window_days =
        !!this.formData.prepaid_return_window_days;
      this.formData.refund_trigger_status_value =
        this.formData.refund_trigger_status;
      this.formData.refund_trigger_status =
        !!this.formData.refund_trigger_status;
    },

    submitConfiguration: function () {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const data = this.formData;
      const configurations = [
        {
          key: "cod_return_window_days",
          value: data.cod_return_window_days_value,
          apply_rule_type: "all",
          customers: [],
        },
        {
          key: "prepaid_return_window_days",
          value: data.prepaid_return_window_days_value,
          apply_rule_type: "all",
          customers: [],
        },
        {
          key: "refund_trigger_status",
          value: data.refund_trigger_status_value,
          apply_rule_type: "all",
          customers: [],
        },
        {
          key: "enable_email_services_for_return",
          value: data.enable_email_services_for_return,
          apply_rule_type: "all",
          customers: [],
        },
        {
          key: "automatically_process_pending_return",
          value: data.automatically_process_pending_return,
          apply_rule_type: "all",
          customers: [],
        },
      ];
      // if (this.formData.cod_return_window_days) {
      //   configurations.push({
      //     key: "cod_return_window_days",
      //     value: data.cod_return_window_days_value,
      //     apply_rule_type: "all",
      //     customers: [],
      //   });
      // }
      // if (this.formData.prepaid_return_window_days) {
      //   configurations.push({
      //     key: "prepaid_return_window_days",
      //     value: data.prepaid_return_window_days_value,
      //     apply_rule_type: "all",
      //     customers: [],
      //   });
      // }
      // if (this.formData.refund_trigger_status) {
      //   configurations.push({
      //     key: "refund_trigger_status",
      //     value: data.refund_trigger_status_value,
      //     apply_rule_type: "all",
      //     customers: [],
      //   });
      // }
      const payload = {
        type: "returns",
        warehouse_id: this.warehouseDetails.index,
        configurations,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      return new Promise(() => {
        ApiService.post(
          `/warehouse_management/main_configuration/store`,
          payload
        )
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: "The Configuration settings are updated",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            // this.expanded = false;
          })
          .catch()
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      });
    },
  },

  computed: {
    codReturnWindowError: function () {
      return this.handleFormValidation("cod_return_window_days_value", this);
    },
    prepaidReturnWindowError: function () {
      return this.handleFormValidation(
        "prepaid_return_window_days_value",
        this
      );
    },
    refundTriggerStatusError: function () {
      return this.handleFormValidation("refund_trigger_status_value", this);
    },
  },
};
</script>
