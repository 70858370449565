<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Change Sort Type</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row">
          <div
            class="col-12 h-75px"
            v-if="serverData?.hasOwnProperty('organizations')"
          >
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              :items="serverData.organizations"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="organization_idErrors"
              @blur="$v.formData.organization_id.$touch()"
              @change="toggleOrganizations"
            />
          </div>
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.finger_id"
              label="Finger"
              :items="serverData.fingers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="finger_idErrors"
              @blur="$v.formData.finger_id.$touch()"
            />
          </div>
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.chutes"
              label="Chutes"
              :items="filteredChutes"
              item-text="text"
              item-value="index"
              multiple
              small-chips
              deletable-chips
              dense
              outlined
              clearable
              :error-messages="chutesErrors"
              @blur="$v.formData.chutes.$touch()"
            />
          </div>
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.sort_type"
              label="Sort Type"
              :items="serverData.sort_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="sort_typeErrors"
              @blur="$v.formData.sort_type.$touch()"
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ChangeSortTypeModal",
  mixins: [validationMixin],
  props: ["refresher"],
  validations: {
    formData: {
      organization_id: { required },
      sort_type: { required },
      finger_id: { required },
      chutes: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: {},
    filteredChutes: [],
    formData: {
      organization_id: null,
      sort_type: null,
      finger_id: null,
      chutes: [],
    },
  }),
  methods: {
    pageLoader(state = false) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/wes/wesChutes/get_additional_data")
        .then(({ data }) => {
          this.serverData = data.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleOrganizations() {
      if (this.formData.organization_id) {
        this.pageLoader(true);
        ApiService.post("/wes/wesChutes/get_additional_data", {
          organization_id: this.formData.organization_id,
        })
          .then(({ data }) => {
            this.serverData = { ...this.serverData, ...data.data };
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    resetFormData() {
      this.formData = {
        organization_id: null,
        sort_type: null,
        finger_id: null,
        chutes: null,
      };

      this.serverData = {};
    },

    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(`/wes/wesChutes/change_sort_type`, this.formData)
        .then(() => {
          swalEdited();
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    headerText: function() {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    organization_idErrors: function() {
      return this.handleFormValidation("organization_id", this);
    },
    sort_typeErrors: function() {
      return this.handleFormValidation("sort_type", this);
    },
    finger_idErrors: function() {
      return this.handleFormValidation("finger_id", this);
    },
    chutesErrors: function() {
      return this.handleFormValidation("chutes", this);
    },
    selectedFinger() {
      return this.formData.finger_id;
    },
  },
  watch: {
    selectedFinger(newValue) {
      // console.log("newValue", newValue);
      if (newValue) {
        this.filteredChutes = this.serverData.fingers.find(
          (finger) => finger.index === newValue
        ).chutes;
      }
    },
  },
};
</script>
